const middleware = {}

middleware['digibord'] = require('../middleware/digibord.js')
middleware['digibord'] = middleware['digibord'].default || middleware['digibord']

middleware['home'] = require('../middleware/home.js')
middleware['home'] = middleware['home'].default || middleware['home']

middleware['maintenanceMode'] = require('../middleware/maintenanceMode.js')
middleware['maintenanceMode'] = middleware['maintenanceMode'].default || middleware['maintenanceMode']

middleware['student'] = require('../middleware/student.js')
middleware['student'] = middleware['student'].default || middleware['student']

middleware['tutor'] = require('../middleware/tutor.js')
middleware['tutor'] = middleware['tutor'].default || middleware['tutor']

export default middleware
