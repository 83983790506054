export const state = () => ({
  classroomId: null,
  tutor: null,
  students: [],
  remote: true,
  workedOnTasks: [],
  currentTask: null,
  showStrategy: false,
  showJumpNumberLine: false,
  videoRoomUrl: null,
  userAudioVideo: new Map(),
  lastUpdated: null
})

export const getters = {
  hasSession: (state) => {
    return state.classroomId !== null
  },
  taskSelected: (state) => {
    return state.currentTask !== null
  },
  getStudentById: (state) => (studentId) => {
    return state.students.find((student) => student.id === studentId)
  },
  currentFeedback(state, getters) {
    if (getters.studentWithTurn === null) {
      return null
    }
    const studentFeedback = state.feedback.find(
      (feedback) =>
        feedback.itemId === getters.currentItem.id &&
        feedback.studentId === getters.studentWithTurn.id
    )
    return studentFeedback === undefined ? null : studentFeedback
  },
  incorrectFeedback(state, getters) {
    return state.feedback
      .map((feedback) => {
        return {
          ...feedback,
          itemIndex: getters.getItemIndexById(feedback.itemId),
          student: getters.getStudentById(feedback.studentId)
        }
      })
      .filter(
        (feedback) =>
          feedback.value === 0 &&
          feedback.itemId !== getters.currentItem.id &&
          feedback.student.online
      )
  },
  userHasTutorPermission: (state, getters, rootState) => {
    if (state.tutor === null) {
      return false
    }
    return state.tutor.id === rootState.auth.user.id
  },
  userHasTurn: (state, getters, rootState) => {
    if (getters.studentWithTurn === null) {
      return false
    }
    return getters.studentWithTurn.id === rootState.auth.user.id
  },
  tutorIsOnline: (state) => {
    if (state.tutor === null) {
      return false
    }
    return state.tutor.online
  },
  studentWithTurn: (state) => {
    const studentWithTurn = state.students.find((student) => {
      return student.hasTurn === true
    })
    return studentWithTurn === undefined ? null : studentWithTurn
  }, // set of getters that determine permissions in application:
  workedOnTask: (state) => (task) => {
    return (
      state.workedOnTasks.find(
        (workedOnTask) =>
          workedOnTask.taskId === task.id &&
          JSON.stringify(workedOnTask.context) ===
            JSON.stringify(task.taskContext)
      ) !== undefined
    )
  },
  tutorWithAudioVideo: (state) => {
    if (state.tutor === null) {
      return null
    }
    if (false === state.userAudioVideo.has(state.tutor.id)) {
      return state.tutor
    }
    return { ...state.tutor, ...state.userAudioVideo.get(state.tutor.id) }
  },
  studentsWithAudioVideo: (state) => {
    return state.students.map((student) => {
      if (false === state.userAudioVideo.has(student.id)) {
        return student
      }
      return { ...student, ...state.userAudioVideo.get(student.id) }
    })
  }
}

export const mutations = {
  SET_CLASSROOM_ID(state, classroomId) {
    state.classroomId = classroomId
  },
  SET_TUTOR(state, tutor) {
    state.tutor = tutor
  },
  SET_STUDENTS(state, students) {
    state.students = students
  },
  SET_REMOTE(state, remote) {
    state.remote = remote
  },
  SET_USER_AUDIO_VIDEO(
    state,
    {
      userId,
      audioTrack,
      videoTrack,
      audioMuted,
      videoMuted,
      audioBlocked,
      videoBlocked
    }
  ) {
    const audioVideoForUsers = new Map(state.userAudioVideo)
    audioVideoForUsers.set(userId, {
      audioTrack,
      videoTrack,
      audioMuted,
      videoMuted,
      audioBlocked,
      videoBlocked
    })
    state.userAudioVideo = audioVideoForUsers
  },
  RESET_USER_AUDIO_VIDEO(state, userId) {
    state.commit('tutoring/SET_USER_AUDIO_VIDEO', {
      userId,
      audioTrack: null,
      videoTrack: null,
      audioMuted: false,
      videoMuted: false,
      audioBlocked: false,
      videoBlocked: false
    })
  },
  SET_CURRENT_TASK(state, currentTask) {
    state.currentTask = currentTask
  },
  SET_WORKED_ON_TASKS(state, workedOnTasks) {
    if (JSON.stringify(workedOnTasks) !== JSON.stringify(state.workedOnTasks)) {
      state.lastUpdated = new Date()
      state.workedOnTasks = workedOnTasks
    }
  },
  SET_SHOW_STRATEGY(state, toggle) {
    state.showStrategy = toggle
  },
  SET_SHOW_JUMP_NUMBER_LINE(state, toggle) {
    state.showJumpNumberLine = toggle
  },
  SET_VIDEOROOM_URL(state, url) {
    state.videoRoomUrl = url
  },
  RESET_VIDEOROOM_URL(state) {
    state.videoRoomUrl = null
  }
}

export const actions = {
  async update(
    { commit, dispatch, state },
    {
      id,
      students,
      tutor,
      remote,
      currentTask,
      workedOnTasks,
      videoRoomUrl,
      showStrategy,
      showJumpNumberLine
    }
  ) {
    if (id !== undefined) {
      commit('SET_CLASSROOM_ID', id)
    }
    if (tutor !== undefined) {
      commit('SET_TUTOR', tutor)
    }
    if (students !== undefined) {
      commit('SET_STUDENTS', students)
    }
    if (remote !== undefined) {
      commit('SET_REMOTE', remote)
    }
    if (currentTask !== undefined) {
      if (currentTask === null) {
        dispatch('task/reset', null, { root: true })
      }
      if (
        currentTask !== null &&
        JSON.stringify(state.currentTask) !== JSON.stringify(currentTask)
      ) {
        dispatch('task/set', await this.$axios.$get(`/classrooms/task`), {
          root: true
        })
      }
      commit('SET_CURRENT_TASK', currentTask)
    }
    if (workedOnTasks !== undefined) {
      commit('SET_WORKED_ON_TASKS', workedOnTasks)
    }
    if (videoRoomUrl !== undefined) {
      commit('SET_VIDEOROOM_URL', videoRoomUrl)
    }
    if (showStrategy !== undefined) {
      commit('SET_SHOW_STRATEGY', showStrategy)
    }
    if (showJumpNumberLine !== undefined) {
      commit('SET_SHOW_JUMP_NUMBER_LINE', showJumpNumberLine)
    }
  },
  // action to stop tutoring session:
  async stop({ commit }) {
    commit('SET_CLASSROOM_ID', null)
    commit('SET_TUTOR', null)
    commit('SET_STUDENTS', [])
    commit('SET_REMOTE', true)
    commit('SET_CURRENT_TASK', null)
    commit('SET_WORKED_ON_TASKS', [])
    commit('SET_VIDEOROOM_URL', null)
    commit('SET_SHOW_STRATEGY', false)
    commit('SET_SHOW_JUMP_NUMBER_LINE', false)
    commit('RESET_VIDEOROOM_URL')
  }
}
