import { RoomEvents } from 'shared'

export default function (nuxt) {
  const { $websocket, app, store } = nuxt
  $websocket.on(RoomEvents.ROOM_FINISHED, async () => {
    await store.dispatch('tutoring/stop')
    if (app.router.currentRoute.name === 'tutoring') {
      app.router.push({ path: '/' })
    }
  })
}
