import { bootstrap } from 'schoollabs-client'
import messages from '@/assets/doc/messages.md'

export default async (nuxt, inject) => {
  const { store } = nuxt
  if (await bootstrap(nuxt, inject)) {
    await store.dispatch('userSettings/load')
    await store.dispatch('taskFilters/load')
    await store.dispatch('messages/update', { messages })
    if (store.state.auth.user && store.state.auth.user.id) {
      checkSessionTime(store.state.auth.user.id, store)
    }
  }
}

function checkSessionTime(userId, store) {
  store.dispatch('userSettings/initSessionTime', userId)
}
