import { RoomEvents } from 'shared'

export default function (nuxt) {
  const { $websocket, app, store } = nuxt
  $websocket.on(RoomEvents.ROOM_JOINED, async (data) => {
    await store.dispatch('tutoring/update', data)
    if (app.router.currentRoute !== 'tutoring') {
      app.router.push({ path: '/tutoring' })
    }
  })
}
