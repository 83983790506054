import moment from 'moment'
import Cookies from 'js-cookie'

export const state = () => ({
  settings: {},
  secondsPassed: null,
  timeUp: false
})

export const getters = {
  getSetting: (state) => (key) => {
    if (state.settings[key] === undefined) {
      return null
    }
    return state.settings[key]
  },
  getInputTimeout: (state, getters) => getters.getSetting('inputTimeout'),
  getAutoplayAudio: (state, getters) => getters.getSetting('autoplayAudio'),
  getPromptExampleItem: (state, getters) =>
    getters.getSetting('promptExampleItem'),
  getStrategyRequired: (state, getters) =>
    getters.getSetting('strategyRequired'),
  getStrategyMode: (state, getters) => getters.getSetting('strategyMode'),
  getShowMathWallSubTitles: (state, getters) =>
    getters.getSetting('showMathWallSubTitles'),
  getMathWallLastSeen: (state, getters) =>
    getters.getSetting('mathWallLastSeen'),
  getCountDirectionRightToLeft: (state, getters) =>
    getters.getSetting('countDirectionRightToLeft'),
  getMaxSessionTime: (state, getters) =>
    parseInt(getters.getSetting('maxSessionTime')),
  getTaskTimeLimit: (state, getters) => getters.getSetting('taskTimeLimit'),
  getShowTimeIndicator: (state, getters) =>
    getters.getSetting('showTimeIndicator'),
  getPauseTask: (state, getters) => getters.getSetting('pauseTask'),
  getIdlePause: (state, getters) => getters.getSetting('idlePause')
}

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings
  },
  SET_SECONDS_PASSED(state, secondsPassed) {
    state.secondsPassed = secondsPassed
  },
  SET_TIME_UP(state, timeUp) {
    state.timeUp = timeUp
  }
}

export const actions = {
  async load({ commit }) {
    const { settings } = await this.$axios.$get('/settings')
    commit('SET_SETTINGS', settings)
  },
  async save({ commit, state }, settings) {
    await this.$axios.$put('/settings', { settings: settings })
    commit('SET_SETTINGS', { ...state.settings, ...settings })
  },
  initSessionTime({ commit, state, getters }, userId) {
    // Check if max session time applicable
    const maxSessionTime = getters.getMaxSessionTime
    if (!maxSessionTime) {
      return
    }
    // Cookie unique for user and day
    const cookieName = userId + moment().format('YYYY-MM-DD')

    // Define function to check if second should be added and if session time expired
    const checkTime = () => {
      let secondsPassed =
        state.secondsPassed === null
          ? Cookies.get(cookieName)
          : state.secondsPassed
      if (!secondsPassed) {
        secondsPassed = 0
      }
      if (secondsPassed >= maxSessionTime * 60) {
        clearInterval(sessionTimeInterval)
        commit('SET_TIME_UP', true)
      } else {
        const currentRoute = this.app.router.currentRoute.name
        if (['task', 'task-id'].includes(currentRoute)) {
          secondsPassed++
        }
        Cookies.set(cookieName, secondsPassed, { expires: 1, secure: false })
      }
      commit('SET_SECONDS_PASSED', secondsPassed)
    }
    checkTime()
    const sessionTimeInterval = setInterval(checkTime, 1000)
  }
}
