<template>
  <div class="main-container">
    <NavigationBar v-if="user" :user="user" @logout="logout()">
      <Logo class="cursor-pointer mr-12" @click="home()" />
      <template #buttons>
        <IconButton v-if="hasRsLicense" @click="navigateTo('/tutoring')">
          <IconTutoring /> Begeleid oefenen
        </IconButton>
        <IconButton @click="navigateTo('/task')">
          <IconTask /> Zelfstandig oefenen
        </IconButton>
        <IconButton v-if="hasDigibord" @click="navigateTo('/digibord')">
          <IconDigibord /> Digibord
        </IconButton>
      </template>
      <template #userMenu>
        <span
          v-if="(isDeveloper || debug) && getMaxSessionTime"
          class="mb-2 text-green"
        >
          {{ secondsPassed }}/{{ getMaxSessionTime * 60 }} seconden
        </span>
      </template>
    </NavigationBar>
    <Loader v-if="busy" class="p-10" />
    <Nuxt v-else-if="canAccessPage" />
    <div v-else class="flex flex-col h-screen items-center justify-center">
      <div
        class="flex flex-col items-center justify-center text-center"
        style="max-width: 50em"
      >
        <template v-if="errorMessage">
          <h1 class="text-3xl mb-2">
            {{ errorMessage }}
          </h1>
        </template>
        <template v-else>
          <Animation name="waiting" class="mb-4" />
          <h1 class="text-3xl mb-2">
            Je oefentijd is voorbij. Je bent klaar voor vandaag!
          </h1>
        </template>
      </div>
    </div>
    <ModalContainer />
  </div>
</template>
<script>
import Logo from '@/components/Logo.vue'
import { mapGetters, mapState } from 'vuex'
import {
  ModalContainer,
  Loader,
  IconDigibord,
  IconTask,
  IconTutoring,
  IconButton,
  NavigationBar
} from 'component-library'
import Animation from '@/components/Animation'

export default {
  components: {
    IconButton,
    IconTutoring,
    IconTask,
    IconDigibord,
    Animation,
    ModalContainer,
    Logo,
    NavigationBar,
    Loader
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      timeUp: (state) => state.userSettings.timeUp,
      busy: (state) => state.auth.busy,
      errorMessage: (state) => state.auth.errorMessage,
      slow: (state) => state.auth.slow,
      secondsPassed: (state) => state.userSettings.secondsPassed,
      licenses: (state) => state.auth.licenses
    }),
    ...mapGetters({
      getMaxSessionTime: 'userSettings/getMaxSessionTime',
      isDeveloper: 'auth/isDeveloper'
    }),
    canAccessPage() {
      return (
        (!['task', 'task-id'].includes(this.$route.name) || !this.timeUp) &&
        !this.errorMessage &&
        !this.slow
      )
    },
    hasRsLicense() {
      return this.licenses.includes('Rekensprint')
    },
    debug() {
      return this.$config.appEnv === 'dev'
    },
    hasDigibord() {
      return this.licenses.includes('Digibord')
    }
  },
  methods: {
    async logout() {
      window.location.href = '/auth/logout'
    },
    home() {
      this.$router.push({ path: '/' })
    },
    navigateTo(route) {
      this.$router.push({
        path: route
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 4.125rem;
}
</style>
