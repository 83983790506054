import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _24ef2177 = () => interopDefault(import('../pages/digibord/index.vue' /* webpackChunkName: "pages/digibord/index" */))
const _344898ab = () => interopDefault(import('../pages/maintenance-mode.vue' /* webpackChunkName: "pages/maintenance-mode" */))
const _3223a543 = () => interopDefault(import('../pages/no-license.vue' /* webpackChunkName: "pages/no-license" */))
const _11d7fee0 = () => interopDefault(import('../pages/student/index.vue' /* webpackChunkName: "pages/student/index" */))
const _d034a954 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _e28cbeca = () => interopDefault(import('../pages/tutor/index.vue' /* webpackChunkName: "pages/tutor/index" */))
const _277b3a06 = () => interopDefault(import('../pages/tutoring/index.vue' /* webpackChunkName: "pages/tutoring/index" */))
const _7bb7efb4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c415d4c2 = () => interopDefault(import('../pages/digibord/_id.vue' /* webpackChunkName: "pages/digibord/_id" */))
const _81633a68 = () => interopDefault(import('../pages/item/_id.vue' /* webpackChunkName: "pages/item/_id" */))
const _33602678 = () => interopDefault(import('../pages/task-result-stats/_id.vue' /* webpackChunkName: "pages/task-result-stats/_id" */))
const _534fa572 = () => interopDefault(import('../pages/task-result/_id.vue' /* webpackChunkName: "pages/task-result/_id" */))
const _3a728b3e = () => interopDefault(import('../pages/task/_id.vue' /* webpackChunkName: "pages/task/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/digibord",
    component: _24ef2177,
    name: "digibord"
  }, {
    path: "/maintenance-mode",
    component: _344898ab,
    name: "maintenance-mode"
  }, {
    path: "/no-license",
    component: _3223a543,
    name: "no-license"
  }, {
    path: "/student",
    component: _11d7fee0,
    name: "student"
  }, {
    path: "/task",
    component: _d034a954,
    name: "task"
  }, {
    path: "/tutor",
    component: _e28cbeca,
    name: "tutor"
  }, {
    path: "/tutoring",
    component: _277b3a06,
    name: "tutoring"
  }, {
    path: "/",
    component: _7bb7efb4,
    name: "index"
  }, {
    path: "/digibord/:id",
    component: _c415d4c2,
    name: "digibord-id"
  }, {
    path: "/item/:id?",
    component: _81633a68,
    name: "item-id"
  }, {
    path: "/task-result-stats/:id",
    component: _33602678,
    name: "task-result-stats-id"
  }, {
    path: "/task-result/:id",
    component: _534fa572,
    name: "task-result-id"
  }, {
    path: "/task/:id",
    component: _3a728b3e,
    name: "task-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
