import DailyIframe from '@daily-co/daily-js'

let callObject

const callEvents = ['joined-meeting', 'left-meeting', 'error']
const participantEvents = [
  'participant-joined',
  'participant-left',
  'participant-updated'
]

export default function (store) {
  store.subscribe(async ({ type, payload }) => {
    if (type === 'tutoring/SET_VIDEOROOM_URL') {
      try {
        await joinCall(payload)
      } catch (error) {
        console.log(`Failed to join call: ${error}`)
      }
    }
    if (type === 'tutoring/RESET_VIDEOROOM_URL') {
      console.log(`Video room URL reset: leaving call`)
      if (!callObject) {
        return
      }
      await callObject.leave()
    }

    if (type === 'videoSettings/SET_VIDEO_DEVICE_ID') {
      await updateDevices()
    }
    if (type === 'videoSettings/SET_AUDIO_DEVICE_ID') {
      await updateDevices()
    }
    if (type === 'videoSettings/SET_MUTE_VIDEO') {
      updateMuteStates()
    }
    if (type === 'videoSettings/SET_MUTE_AUDIO') {
      updateMuteStates()
    }
  })
  const joinCall = async function (payload) {
    if (!callObject) {
      await createCallObject()
    }
    const urlObject = new URL(payload)
    const url = `${urlObject.origin}${urlObject.pathname}`
    const token = urlObject.searchParams.get('t')
    console.log(`Joining call URL=${url}, token=${token}`)
    const response = await callObject.join({ url, token })
    await updateDevices()
    updateMuteStates()

    handleParticipantMedia(response.local)
  }

  const handleParticipantState = ({ action, participant }) => {
    if (action === 'participant-left') {
      const userId = participant.user_id // mapped to our user IDs
      store.commit('tutoring/RESET_USER_AUDIO_VIDEO', userId)
      return
    }
    handleParticipantMedia(participant)
  }

  const handleParticipantMedia = (participant) => {
    let audioTrack = participant.audioTrack || null
    let videoTrack = participant.videoTrack || null
    const userId = participant.user_id // mapped to our local IDs
    if (participant.local) {
      audioTrack = null
    }
    store.commit('tutoring/SET_USER_AUDIO_VIDEO', {
      userId,
      audioTrack,
      videoTrack,
      audioMuted:
        participant.tracks.audio.off !== undefined &&
        participant.tracks.audio.off.byUser,
      videoMuted:
        participant.tracks.video.off !== undefined &&
        participant.tracks.video.off.byUser,
      audioBlocked:
        participant.tracks.audio.blocked !== undefined &&
        participant.tracks.audio.blocked.byPermissions,
      videoBlocked:
        participant.tracks.video.blocked !== undefined &&
        participant.tracks.video.blocked.byPermissions
    })
  }

  const handleCallState = async () => {
    const meetingState = callObject.meetingState()
    console.log(`meeting state event: ${meetingState}`)
    switch (meetingState) {
      case 'left-meeting':
        await leaveCall()
        break
      case 'error':
        console.error(
          'An error was thrown while interacting with the callObject'
        )
        break
      default:
        break
    }
  }

  const leaveCall = async () => {
    for (const callEvent of callEvents) {
      callObject.off(callEvent, handleCallState)
    }
    for (const callEvent of participantEvents) {
      callObject.off(callEvent, handleParticipantState)
    }
    await callObject.destroy()
    callObject = undefined
  }

  const createCallObject = async () => {
    await store.dispatch('videoSettings/init')
    callObject = DailyIframe.createCallObject({})

    for (const event of callEvents) {
      callObject.on(event, handleCallState)
    }
    for (const participantEvent of participantEvents) {
      callObject.on(participantEvent, handleParticipantState)
    }
  }

  const updateDevices = async () => {
    if (!callObject) {
      return
    }
    await callObject.setInputDevicesAsync({
      audioDeviceId: store.state.videoSettings.audioDeviceId,
      videoDeviceId: store.state.videoSettings.videoDeviceId
    })
  }

  const updateMuteStates = () => {
    if (!callObject) {
      return
    }
    callObject.setLocalVideo(!store.state.videoSettings.muteVideo)
    callObject.setLocalAudio(!store.state.videoSettings.muteAudio)
  }
}
