import videoCallPlugin from '@/plugins/videoCall'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

export const plugins = [
  videoCallPlugin,
  createPersistedState({
    paths: ['videoSettings'],
    storage: {
      getItem: (key) => {
        return Cookies.get(key)
      },
      setItem: (key, value) =>
        Cookies.set(key, value, { expires: 365, secure: false }),
      removeItem: (key) => Cookies.remove(key)
    }
  })
]
