import { Item } from 'item-player'

export const state = () => ({
  currentProgramId: null,
  task: null,
  items: null,
  templateVariables: null,
  exampleItems: [],
  shownExampleItemIds: [],
  currentExampleItemId: null,
  assets: [],
  maxDuration: null,
  uvaPracticeMode: null
})

export const getters = {
  itemCount: (state) => {
    return state.items.length
  },
  currentExampleItem: (state) => {
    return (
      state.exampleItems.find(
        (exampleItem) => exampleItem.id === state.currentExampleItemId
      ) || null
    )
  }
}

export const mutations = {
  SET_CURRENT_PROGRAM_ID(state, id) {
    state.currentProgramId = id
  },
  SET_TASK(state, task) {
    state.task = task
  },
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_TEMPLATE_VARIABLES(state, templateVariables) {
    state.templateVariables = templateVariables
  },
  SET_ASSETS(state, assets) {
    state.assets = assets
  },
  SET_MAX_DURATION(state, maxDuration) {
    state.maxDuration = maxDuration
  },
  SET_EXAMPLE_ITEMS(state, exampleItems) {
    // reset shown example ids:
    state.shownExampleItemIds = []
    state.exampleItems = exampleItems
  },
  SET_CURRENT_EXAMPLE_ITEM_ID(state, exampleItemId) {
    state.currentExampleItemId = exampleItemId
    state.shownExampleItemIds.push(exampleItemId)
  },
  RESET_CURRENT_EXAMPLE_ITEM(state) {
    state.currentExampleItemId = null
  },
  SET_UVA_PRACTICE_MODE(state, mode) {
    state.uvaPracticeMode = mode
  }
}

export const actions = {
  async loadTask({ dispatch }, taskIdWithContext) {
    if (!taskIdWithContext) {
      dispatch('reset')
    }
    const { taskId, context } = taskIdWithContext
    const data =
      context && context.programTaskId
        ? await this.$axios.$get(`/tasks/program/${context.programTaskId}`)
        : await this.$axios.$get(`/tasks/${taskId}`)
    await dispatch('set', data)
  },
  reset({ commit }) {
    commit('SET_TASK', null)
    commit('SET_ITEMS', null)
    commit('SET_TEMPLATE_VARIABLES', null)
    commit('SET_ASSETS', [])
    commit('SET_MAX_DURATION', null)
    commit('SET_EXAMPLE_ITEMS', [])
    commit('RESET_CURRENT_EXAMPLE_ITEM')
  },
  async set({ commit, dispatch, rootGetters }, data) {
    const {
      task,
      items,
      exampleItems,
      assets,
      maxDuration,
      templateVariables
    } = data
    commit('SET_TASK', task)
    commit('SET_MAX_DURATION', maxDuration)
    commit(
      'SET_ITEMS',
      items.map((item) =>
        Item.createFromObject({
          ...item,
          assets: assets.filter((asset) => {
            return asset.itemId === item.id
          })
        })
      )
    )
    commit(
      'SET_EXAMPLE_ITEMS',
      exampleItems.map((item) =>
        Item.createFromObject({
          ...item,
          assets: assets.filter((asset) => {
            return asset.itemId === item.id
          })
        })
      )
    )
    commit('SET_ASSETS', assets)
    commit('SET_TEMPLATE_VARIABLES', templateVariables)
    await dispatch(
      'determineCurrentExampleItem',
      rootGetters['resultSession/currentItem']
    )
  },
  determineCurrentExampleItem({ commit, state, rootGetters }, item) {
    // the item may not have an example:
    if (!item || !item.exampleItemId) {
      commit('RESET_CURRENT_EXAMPLE_ITEM')
      return
    }
    // prompting the example item may be turned off via a user setting:
    if (!rootGetters['userSettings/getPromptExampleItem']) {
      commit('RESET_CURRENT_EXAMPLE_ITEM')
      return
    }
    // the example item may already be prompted:
    if (state.shownExampleItemIds.includes(item.exampleItemId)) {
      commit('RESET_CURRENT_EXAMPLE_ITEM')
      return
    }
    const exampleItemExists = state.exampleItems.some(
      (exampleItem) => exampleItem.id === item.exampleItemId
    )
    if (!exampleItemExists) {
      console.warn(`example item ID=${item.exampleItemId} not in data`)
      commit('RESET_CURRENT_EXAMPLE_ITEM')
      return
    }
    commit('SET_CURRENT_EXAMPLE_ITEM_ID', item.exampleItemId)
  }
}
