import { InputMode } from 'item-player'

export const state = () => ({
  inputMode: InputMode.createNone()
})

export const mutations = {
  SET_INPUT_KEYBOARD(state) {
    state.inputMode = InputMode.createKeyboard()
  },
  SET_INPUT_SPEECH(state) {
    state.inputMode = InputMode.createSpeech()
  }
}

export const actions = {}
