<template>
  <div class="flex flex-row items-end" @click="$emit('click')">
    <LogoRsOnline v-if="hasRsLicense" style="height: 26px; width: auto" />
    <template v-else>
      <LogoTempo style="height: 26px; width: auto" />
      <span
        class="font-bold text-lg italic text-green-dark rounded px-1"
        style="margin-bottom: -2px; margin-left: -2px"
      >
        TEMPO
      </span>
    </template>
    <span
      v-if="$config.appEnv === 'beta'"
      class="ml-2 text-white bg-blue rounded px-1 text-sm"
    >
      BETA
    </span>
  </div>
</template>
<script>
import LogoRsOnline from '@/components/LogoRsOnline.vue'
import { mapState } from 'vuex'
import LogoTempo from '@/components/LogoTempo.vue'

export default {
  components: {
    LogoTempo,
    LogoRsOnline
  },
  computed: {
    ...mapState({
      licenses: (state) => state.auth.licenses
    }),
    hasRsLicense() {
      return this.licenses.includes('Rekensprint')
    }
  }
}
</script>
