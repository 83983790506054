<template>
  <video
    ref="video"
    :src="file"
    :loop="loop"
    playsinline
    muted
    autoplay="autoplay"
  />
</template>

<script>
import waiting from '@/assets/videos/buddy-waiting.mp4'
import hello from '@/assets/videos/buddy-hello.mp4'

const files = { waiting, hello }

export default {
  props: {
    name: { type: String, required: true },
    loop: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: true }
  },
  data() {
    let file = files[this.name]
    if (!file) {
      console.warn(`no file found for animation "${this.name}"`)
      file = ''
    }
    return { file }
  }
}
</script>

<style scoped lang="scss">
video {
  @apply bg-white;
}
</style>
