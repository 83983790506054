import { websocket } from 'schoollabs-client'
export default async function (nuxt, inject) {
  return await websocket(
    {
      ...nuxt,
      heartbeatActiveFunction: (route) => {
        return ['tutoring-new', 'tutoring'].includes(route.name)
      }
    },
    inject
  )
}
