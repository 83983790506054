export const state = () => ({
  devices: [],
  videoDeviceId: null,
  audioDeviceId: null,
  muteVideo: false,
  muteAudio: false
})

export const getters = {
  getDevicesByType: (state) => (type) => {
    return state.devices
      .filter((device) => device.kind === type && device.deviceId !== '')
      .map((device) => {
        return {
          id: device.deviceId,
          content: device.label
        }
      })
  },
  deviceExists: (state) => (deviceId) => {
    return state.devices.some((device) => device.deviceId === deviceId)
  },
  videoDevices: (state, getters) => {
    return getters.getDevicesByType('videoinput')
  },
  audioDevices: (state, getters) => {
    return getters.getDevicesByType('audioinput')
  },
  videoBlocked: (state) => {
    return state.devices.some(
      (device) => device.kind === 'videoinput' && device.label === ''
    )
  },
  audioBlocked: (state) => {
    return state.devices.some(
      (device) => device.kind === 'audioinput' && device.label === ''
    )
  }
}

export const mutations = {
  SET_DEVICES(state, devices) {
    state.devices = devices
  },
  SET_VIDEO_DEVICE_ID(state, deviceId) {
    state.videoDeviceId = deviceId
  },
  SET_AUDIO_DEVICE_ID(state, deviceId) {
    state.audioDeviceId = deviceId
  },
  SET_MUTE_VIDEO(state, mute) {
    state.muteVideo = mute
  },
  SET_MUTE_AUDIO(state, mute) {
    state.muteAudio = mute
  }
}

export const actions = {
  async init({ commit, state, getters }) {
    commit('SET_DEVICES', await navigator.mediaDevices.enumerateDevices())
    if (
      state.videoDeviceId === null ||
      getters.deviceExists(state.videoDeviceId) === false
    ) {
      if (getters.videoDevices.length > 0) {
        commit('SET_VIDEO_DEVICE_ID', getters.videoDevices[0].id)
      }
    }
    if (
      state.audioDeviceId === null ||
      getters.deviceExists(state.audioDeviceId) === false
    ) {
      if (getters.audioDevices.length > 0) {
        commit('SET_AUDIO_DEVICE_ID', getters.audioDevices[0].id)
      }
    }
  }
}
