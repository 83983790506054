import { RoomCommands } from 'shared'

export default function (nuxt) {
  const { $websocket, app, store, route } = nuxt
  $websocket.on(RoomCommands.LEAVE_ROOM, async () => {
    await store.dispatch('tutoring/stop')
    if (route.name.startsWith('tutoring')) {
      app.router.push({ path: '/' })
    }
  })
}
