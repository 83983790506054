export const state = () => ({
  activeMode: 'all',
  activeSearchTerm: '',
  activeEditionId: 'rekensprint-basis',
  activeWeekCardNumber: null,
  activeDayNumber: null,
  activeLearningLine: null,
  activeBrickId: null,
  activeCustomProgramId: null
})

export const mutations = {
  SET_ACTIVE_MODE(state, mode) {
    state.activeMode = mode
  },
  SET_ACTIVE_SEARCH_TERM(state, searchTerm) {
    state.activeSearchTerm = searchTerm
  },
  SET_ACTIVE_EDITION_ID(state, editionId) {
    state.activeEditionId = editionId
  },
  SET_ACTIVE_WEEK_CARD_NUMBER(state, weekCardNumber) {
    state.activeWeekCardNumber = weekCardNumber ? '' + weekCardNumber : null
  },
  SET_ACTIVE_DAY_NUMBER(state, dayNumber) {
    state.activeDayNumber = dayNumber ? '' + dayNumber : null
  },
  SET_ACTIVE_LEARNING_LINE(state, learningLine) {
    state.activeLearningLine = learningLine
  },
  SET_ACTIVE_BRICK_ID(state, brickId) {
    state.activeBrickId = brickId
  },
  SET_ACTIVE_CUSTOM_PROGRAM_ID(state, customProgramId) {
    state.activeCustomProgramId = customProgramId
  }
}

export const actions = {
  async load({ commit, rootState }) {
    const filters = rootState.userSettings.settings.taskFilters
    if (!filters) {
      return
    }
    if (filters.activeMode) {
      commit('SET_ACTIVE_MODE', filters.activeMode)
    }
    if (filters.activeSearchTerm) {
      commit('SET_ACTIVE_SEARCH_TERM', filters.activeSearchTerm)
    }
    if (filters.activeMode) {
      commit('SET_ACTIVE_MODE', filters.activeMode)
    }
    if (filters.activeEditionId) {
      commit('SET_ACTIVE_EDITION_ID', filters.activeEditionId)
    }
    if (filters.activeWeekCardNumber) {
      commit('SET_ACTIVE_WEEK_CARD_NUMBER', filters.activeWeekCardNumber)
    }
    if (filters.activeDayNumber) {
      commit('SET_ACTIVE_DAY_NUMBER', filters.activeDayNumber)
    }
    if (filters.activeLearningLine) {
      commit('SET_ACTIVE_LEARNING_LINE', filters.activeLearningLine)
    }
    if (filters.activeBrickId) {
      commit('SET_ACTIVE_BRICK_ID', filters.activeBrickId)
    }
    if (filters.activeCustomProgramId) {
      commit('SET_ACTIVE_CUSTOM_PROGRAM_ID', filters.activeCustomProgramId)
    }
  },
  async save({ dispatch, state }) {
    await dispatch(
      'userSettings/save',
      {
        taskFilters: {
          activeMode: state.activeMode,
          activeSearchTerm: state.activeSearchTerm,
          activeEditionId: state.activeEditionId,
          activeWeekCardNumber: state.activeWeekCardNumber,
          activeDayNumber: state.activeDayNumber,
          activeLearningLine: state.activeLearningLine,
          activeBrickId: state.activeBrickId,
          activeCustomProgramId: state.activeCustomProgramId
        }
      },
      { root: true }
    )
  }
}
